.label {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3xs);
  width: 100%;
  --input-size: min(var(--body2-size), 16px);
  & .labelText {
    @extend %typo-label;
    line-height: 1.5;
    font-weight: 400;
  }

  &.error {
    & .labelText {
      color: var(--color-red);
    }

    & .input {
      --border-color: var(--color-red);
    }
  }
}

.input {
  --border-color: var(--color-input-border);

  background-color: transparent;
  border: 1px solid var(--border-color);
  padding-right: var(--spacing-2xs);
  font-family: inherit;
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  width: 100%;
  height: var(--input-height);

  &:focus-within {
    --border-color: var(--color-input-focus);
    outline: 1px solid var(--border-color);
  }

  &:has(> input:disabled) {
    --border-color: var(--neutral-3);
    color: var(--color-text-disabled);
  }

  &.search {
    border: none;
    border-bottom: 1px solid var(--pdp-button-border);
    outline: none;
    justify-content: space-between;
    padding-right: 0;

    &:focus-within {
      --border-color: var(--color-input-focus);
      border-width: 2px;
      outline: none;
    }

    & input {
      @extend %typo-body1;
      text-transform: uppercase;
      padding-left: 0;
      padding-top: var(--spacing-xs);

      @media (--small) {
        padding-top: var(--spacing-2xs);
        padding-bottom: var(--spacing-sm);
      }

      &::placeholder {
        color: var(--color-inactive-tag);
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: var(--color-inactive-tag);
      }
    }

    & button {
      padding-block: var(--spacing-2xs);
      @media (--small) {
        padding-top: var(--spacing-2xs);
        padding-bottom: var(--spacing-sm);
      }
    }
  }

  &:hover {
    --border-color: var(--color-input-focus);
  }

  & input {
    width: 100%;
    padding: var(--spacing-2xs);
    padding-right: 0;
    background: transparent;
    border: none;
    outline: none;
    /* Must force input font size to be 16px always */
    font-size: var(--input-size);
    &::placeholder {
      color: var(--color-text-secondary);
    }
    &:disabled {
      cursor: not-allowed;
    }

    &[type="search"] {
      appearance: none;
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }

  & .clearIcon {
    display: none;
  }

  &:not(:has(> input:placeholder-shown)) {
    & .searchIcon {
      display: none;
    }

    & .clearIcon {
      cursor: pointer;
      display: flex;
      min-width: fit-content;
      text-decoration: underline;
      text-underline-offset: var(--spacing-6);
    }
  }
}

.inputAction {
  all: unset;
  --svg-color: var(--color-dark);

  appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: var(--input-size);
    height: var(--input-size);
  }
}
