/* Typography tokens */

/*
*** Usage
** @extend %typo-headline1;
** @extend %typo-headline2;
** @extend %typo-headline3;
** @extend %typo-subtitle1;
** @extend %typo-subtitle2;
** @extend %typo-body1;
** @extend %typo-body2;
** @extend %typo-body3;
** @extend %typo-caption1;
** @extend %typo-caption2;
** @extend %typo-caption-product-card;
** @extend %typo-button;
** @extend %typo-label;
** @extend %typo-helper;
** @extend %typo-overline;
**
** Token name corresponds to figma token.
** If the typo element is marked with something like "body2/xs-xl" that means use @extend %typo-body2. 
** Breakpoints are handled through variables. Do not extend these tokens inside of breakpoints.
*/

%typo {
  &-headline {
    &1 {
      font-family: var(--font-monument);
      font-size: var(--headline1-size);
      line-height: 1.2;
      font-weight: 500;
      text-transform: none;
    }

    &2 {
      font-family: var(--font-monument);
      font-size: var(--headline2-size);
      line-height: 1.3;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &-subtitle {
    &1 {
      font-family: var(--font-monument);
      font-size: var(--subtitle1-size);
      line-height: 1.3;
      font-weight: 500;
      text-transform: none;
    }

    &2 {
      font-family: var(--font-monument);
      font-size: var(--subtitle2-size);
      line-height: 1.5;
      font-weight: 500;
      text-transform: none;
    }

    &3 {
      font-family: var(--font-monument);
      font-size: var(--subtitle3-size);
      line-height: 1.5;
      font-weight: 500;
      text-transform: none;
    }
  }

  &-body {
    &1 {
      font-family: var(--font-monument);
      font-size: var(--body1-size);
      line-height: 1.5;
      font-weight: 400;
      text-transform: none;
    }
    &2 {
      font-family: var(--font-monument);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 400;
      text-transform: none;
    }
  }

  &-caption {
    &1 {
      font-family: var(--font-monument);
      font-size: var(--caption1-size);
      line-height: 1.5;
      font-weight: 400;
      text-transform: none;
    }
  }

  &-button {
    font-family: var(--font-monument);
    font-size: var(--font-size-s);
    font-weight: 500;
    line-height: 1;
    text-transform: none;
  }

  &-label {
    font-family: var(--font-monument);
    font-size: var(--font-size-2xs);
    font-weight: 500;
    line-height: 1;
    text-transform: none;
  }

  &-hero {
    font-family: var(--font-monument);
    font-size: var(--hero-size);
    line-height: 0.9;
    text-transform: none;
  }

  &-explore {
    font-family: var(--font-monument);
    font-size: var(--explore-size);
    line-height: 1.25;
    text-transform: uppercase;
  }

  &-byline {
    font-family: var(--font-monument);
    font-size: var(--byline-size);
    text-transform: uppercase;
    line-height: 1.3;
  }
}

%main {
  &-grid {
    --row-gap: 32px;
    --column-gap: 16px;
    gap: var(--row-gap) var(--column-gap);
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    @media (--small) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &-left {
    grid-column: 1/1;
    @media (--small) {
      grid-column: 1/6;

      &-large {
        grid-column: 1/8;
      }

      &-indent {
        grid-column: var(--grid-left-indent, 3/8);

        &-small {
          grid-column: var(--grid-left-indent-small, 2/8);
        }
      }
    }
  }
  &-right {
    grid-column: 1/1;
    @media (--small) {
      grid-column: 8/13;

      &-large {
        grid-column: 6/13;
      }

      &-indent {
        grid-column: var(--grid-right-indent, 6/11);

        &-small {
          grid-column: var(--grid-right-indent-small, 6/12);
        }
      }
    }
  }
}

%chip {
  &-elements {
    display: flex;
    align-items: center;
    &:nth-child(3n + 1) {
      margin-right: var(--spacing-16);
    }
    &:nth-child(3n + 2) {
      margin-right: var(--spacing-32);
    }
    &:nth-child(3n) {
      margin-right: var(--spacing-48);
    }
  }
}

%gap {
  &-scaling {
    display: flex;

    & > * {
      &:nth-child(3n + 1) {
        margin-right: var(--spacing-16);
      }
      &:nth-child(3n + 2) {
        margin-right: var(--spacing-32);
      }
      &:nth-child(3n) {
        margin-right: var(--spacing-48);
      }
    }
  }
}
