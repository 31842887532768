.searchModal {
  --path-closed: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  --path-open: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  position: fixed;
  background-color: var(--neutral-2);
  inset: 0;
  z-index: calc(var(--z-overlay) + 1);
  padding-top: var(--spacing-md);
  overflow-y: auto;

  will-change: clip-path, opacity;
  transition: all 300ms 100ms;

  /* Closed */
  visibility: hidden;
  pointer-events: none;
  clip-path: var(--path-closed);

  &[data-state="open"] {
    /* Open */
    visibility: visible;
    pointer-events: auto;
    clip-path: var(--path-open);
  }

  & .content {
    max-width: var(--page-inset-width);
    margin: 0 auto;
  }
}

.searchClose {
  all: unset;
  --svg-color: var(--color-black);
  display: block;

  margin-left: auto;
  width: var(--icon-md);
  height: var(--icon-md);
  cursor: pointer;

  @media (--small) {
    width: var(--icon-lg);
    height: var(--icon-lg);
    margin-right: var(--spacing-xs);
  }

  & svg {
    width: 100%;
    height: 100%;
  }
}

.searchField {
  display: grid;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  /* Specificity for Input comp */
  & label.input {
    --input-size: var(--body1-size);
    padding-block: var(--spacing-xs);

    @media (--small) {
      padding-top: var(--spacing-2xs);
      padding-bottom: var(--spacing-sm);
    }
  }
}

.title,
.searchTitle,
.noResults {
  @extend %typo-body1;
  text-transform: uppercase;
  justify-self: center;
  text-align: center;
  @media (--small) {
    margin-bottom: var(--spacing-xs);
  }
}

.productSection {
  display: grid;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
  padding-bottom: var(--spacing-lg);

  @media (--small) {
    padding-bottom: var(--spacing-80);
  }

  /* Hide product tags in modal search */
  & :global(.tags) {
    display: none;
  }

  & .button {
    max-width: max-content;
    margin: 0 auto;
    display: block;
  }

  & .noResults {
    & a {
      color: inherit;
      font-weight: 500;
    }
  }
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
