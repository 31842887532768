
.root {
  height:  var(--spacing-sm);
  width: 100%;
  text-decoration: none;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--neutral-2);
  padding: var(--spacing-6) var(--spacing-4);
  position: relative;
  font-size: var(--font-size-2xs);
  animation: fadeIn 0.1s ease-out forwards;

  @media (--small) {
    font-size: var(--font-size-sm);
  }


}

.link {
  width: 100%;
  text-decoration: none;
}


.closeButton {
  position: absolute;
  right: var(--spacing-16);
  top: var(--spacing-4);
  padding: 0 var(--input-padding-h);
  background: transparent;
  border: none;
  border-radius: var(--input-br);
  cursor: pointer;
  color: white;
  fill: white;
  height: 20px;
  width: 20px;

  svg {
    color:white;
    stroke: white;
  }

}


