.navWrapper {
  --_header-transition: 300ms;
  background: var(--neutral-2);
  width: 100%;
  z-index: calc(var(--z-overlay) + 1);
  transition: transform var(--_header-transition) linear;
  @extend %typo-body2;
  position: sticky;
  top: 0;
  margin-bottom: 0;

  @media (--large) {
    margin-bottom: 40px;
  }

  & .mobileMenu {
    width: var(--spacing-16);
    min-width: var(--spacing-16);
    padding: 0;
    background-color: var(--neutral-2);
    &:hover {
      background-color: var(--neutral-2);
    }
  }
}

.isTop {
  background-color: var(--neutral-2);
  padding-block: var(--spacing-md);
  position: relative;
  padding-top: 0;

  @media (--large) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nav {
  display: grid;
  height: var(--header-height);
  padding: var(--spacing-14) 0;
  padding-bottom: var(--spacing-md);
  grid-gap: var(--column-gap);
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  z-index: 2;
  display: grid;
  gap: var(--column-gap);
  /*transition: all var(--_header-transition);*/

  @media (--large) {
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: var(--spacing-14);
  }
}

.right {
  grid-column: 3 / span 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-16);

  @media (--large) {
    grid-column: 9 / span 4;
  }
}

.left {
  display: none;

  @media (--large) {
    grid-column: 3 / span 2;
    display: flex;
    align-items: center;
    gap: var(--spacing-16);
  }
}

.left,
.right {
  @media (--large) {
    width: 100%;
  }
}

.smallLogo {
  grid-column: 1;
}

.logotype {
  --logo-width: 98px;
  width: var(--logo-width);
  grid-column: 2;

  @media (--large) {
    grid-column: 8;
  }
}

.logo {
  width: 35px;
}

.logotype svg,
.logo svg {
  width: 100%;
  height: auto;
  display: block;

  --svg-color: var(--color-primary);
}

.hide:not(:hover) {
  transform: translateY(-100%);
}

.mobile {
  @media (--large) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (--large) {
    display: block;
  }
}

.item {
  display: inline-flex;
  gap: var(--spacing-4);
  align-items: center;
  line-height: 1;
  color: inherit;
}

.nav {
  & .right {
    & .navbarLinks {
      & .linksDesktop {
        display: none;
        @media (--large) {
          display: block;

          text-decoration: none;
          &:hover,
          &:active,
          &:focus-visible {
            text-underline-offset: 9px;
            text-decoration: underline;
          }
        }
      }

      & .linksMobile {
        display: block;
        width: var(--icon-sm);
        height: var(--icon-sm);

        & svg {
          width: var(--icon-sm);
          height: var(--icon-sm);
        }

        @media (--large) {
          display: none;
        }
      }
    }
  }
}

.spacingHome {
  @media (--large) {
    height: calc(11.385vw + 57px); /* header height + padding */
  }
}

/* homepage header */

.navWrapper.home {
  @media (--large) {
    --header-height: auto;
    position: fixed;
    --header-extra-click-area: 60px;
    margin-bottom: 0;

    & .left,
    & .right {
      width: auto;
      flex-shrink: 0;
    }

    & .logotype {
      position: relative;
    }

    & .logotype svg {
      width: var(--logo-width);
      width: max(
        var(--logo-width),
        calc(37.349vw * var(--logo-offset-height))
      ); /* 620px */
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate(0, 50%);
    }

    & .logo svg {
      width: 35px;
      width: max(35px, calc(12.951vw * var(--logo-offset-height))); /* 215px */
      transform: translateY(calc(9% * var(--logo-offset-height)));
      z-index: 100;
      position: relative;
    }

    &.isTop {
      & .nav {
        align-items: flex-end;
      }

      & .logo svg {
        margin: calc(8px * var(--logo-offset-height)) 0 0
          calc(8px * var(--logo-offset-height));
      }

      & .logotype {
        height: 100%;

        & svg {
          left: 50%;
          bottom: 50%;
          bottom: 0;
          transform: translate(-60%, 0%);
        }
      }
    }
  }
}
